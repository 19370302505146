@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300|Raleway|Roboto+Mono');

/* FONTS
font-family: 'Raleway', sans-serif;
font-family: 'Roboto Mono', monospace;
font-family: 'Open Sans Condensed', sans-serif;
*/


.main{
  /* center the main container */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

/* background creates the large dark gray box at the top of the screen */
.background{
  margin: 0px;
  width: 100%;
  height: 230px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #262626;
  z-index: -1;
}

/****  HEADER  ****/

/* header displays the Delve logo */
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 0px;
  padding: 15px 0px 0px 0px;
}

/****  NAVIGATION  ****/

.navigation {
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #BE2931;
  color: #ffffff;
  margin: 20px 0px 0px 0px;
}

.navigation ul {
  margin: 10px;
  padding: 0px;    
}

.navigation ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline;
  margin: 0px 40px;
  padding: 5px 0px 0px 0px;
  font-family: 'Raleway', sans-serif;
}

.navigation ul li a {
  text-decoration: none;
  color: #ffffff;
}

/****  FOOTER  ****/

.footer {
  background-color: #262626;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  padding: 8px;
  font-family: 'Open Sans Condensed', sans-serif;
  color: #ffffff;
  font-size: 0.75em;
}

.footer a:visited, .footer a:link, .footer a:hover {
  color: #ffffffc4;
}

/****  CONTENT SECTIONS  ****/

.card {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin: 20px 0px 0px 0px;
  position: relative;
}

.container {
  background-color: #ffffff;
  width: 900px;
  max-width: 900px;
}

/* SEARCH OPTIONS */

form {
  margin: 10px;
}

.container h2 {
  font-family: 'Raleway', sans-serif;
  background-color: #7a7a7a;
  color: #ffffff;
  margin: 0px;
  padding: 10px 18px;
  font-weight: normal;
}

.container span.search-text {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.75em;
  padding-left: 12px;
}

.search-form input.__text{
  flex: 1;
  font-family: 'Roboto Mono', monospace;
  border: none;
  outline: 0;
  border-radius: 0;
  background-color: #e1e2e1;
  border-bottom: 1px solid #86000b;
  margin: 5px;
  font-size: 0.75em;
  padding: 5px;
  display: inline-block;
}

select {
  font-family: 'Roboto Mono', monospace;
  outline: 0;
  border-radius: 0;
  background-color: #e1e2e1;
  border: 1px solid #86000b;
  margin: 5px;
  font-size: 0.75em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px;
}

.search-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'Roboto Mono', monospace;
  margin: 10px 0px 10px 5px;
  font-size: 0.75em;
  padding: 5px;
  display: inline;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  background-color: #86000b;
  border: 1px solid #86000b !important;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  color: #ffffff;
  display: flex;
  align-items: center;
}

.search-button:active {
  box-shadow: none;
  transform: translateY(1px) translateX(1px);
}

span.input-notation {
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  text-align: center;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300|Raleway|Roboto+Mono');


/* RESULTS CONTAINER FORMATTING */

.results h3 {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  padding-bottom: 20px;
}

.rt-table {
  padding: 15px;
  font-size: 15px;
  font-family: 'Open Sans Condensed', sans-serif;
}

.rt-td {
  white-space: normal !important;
}

div.numeric {
  text-align: right !important;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 10px 0px 20px 0px;
}

.download-link {
  font-family: 'Raleway', sans-serif;
  font-size: 10pt;
  text-decoration: none;
  color: #86000b;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

div.downloadpad {
  width: 40%;
}

p {
  padding: 20px;
  font-family: 'Raleway', sans-serif;
  word-wrap: break-word;
}

.results {
  font-family: 'Open Sans Condensed', sans-serif;
  color: #000000;
  padding: 10px 30px;
}

table {
  border-collapse: collapse;
  color: #262626;
  width: 840px;
}
table th {
  font-weight: bold;
}
table td, table th {
  border: 1px solid #262626;
}
table tr:first-child th {
  border-top: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:first-child, table tr th:first-child {
  border-left: 0;
}
table tr td:last-child, table tr th:last-child {
  border-right: 0;
}

th, td {
  padding: 5px;
}

td.numeric {
  text-align: right !important;
}

.committee-links {
  position: absolute;
  top: 5px;
  right: 5px;
}

.race-report-link {
  overflow: auto;
  clear: left;
  display: inline;
}