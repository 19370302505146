html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #e1e2e1;
  margin-bottom:80px;
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
  color: #000000;
  min-width: 1000px;
}